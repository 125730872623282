@use '/styles/colors.module';

.link-item {
  &:hover {
    color: colors.$global-primary-500;
  }

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}
